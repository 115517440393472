/*****************************
  Layout
*****************************/

/* Section Title */
.section-title {
	margin-bottom: 50px;

	.title {
		margin-bottom: 20px;
	}

	p {
		margin-bottom: 30px;
	}

}

/* Get touch */
.get-touch {
    background: $gray-1;
    padding: 30px;
    text-align: center;
    position: relative;
    height: 350px;
    overflow: hidden;
}

.get-touch svg{
    position: absolute;
    left: 0px;
    width: 100%;
    bottom: 0;
    height: auto;
}

/* Text bg image */
.clipped-title {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: url(../images/bg/06.jpg);
    font-weight: 900;
    color: $primary;
}

/* Sticky Image */
.sticky-img {
  position: fixed;
  top: 90px;
  width: 50%;
}
.sticky-img.right-split{
  right: 0;
}
.sticky-img.left-split{
  left: 0;
}

/* Subscribe */
.subscribe {
    position: relative;
    .form-group {
      .form-control {
        padding-right: 75px;
      }

    }

    button {
      position: absolute;
      right: 10px;
      top: 50%;
      padding: 23px 10px;
      background: none;
      border: none;
      transform: translateY(-50%);
    }
}

/* Frame Layout */
.frame-layout{
  padding: 20px;
}

/* Sticky column */
.is-sticky {
  position: sticky;
  top: 130px;
}

/* Back To Top */
.back-to-top {
  background: $bg-primary-gradient;
  border-radius: $border-radius;
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: inline-block;
  font-size: 16px;
  color: $white;
  text-align: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  cursor: pointer;
  transition: width 0.5s ease-in-out, margin 0.5s ease-in-out, border-radius 0.25s ease-in-out, color 0.25s ease-in-out;
  z-index: 9;
}
.back-to-top:hover, .back-to-top.auto {
  color: rgba($white, 0);
  transition: width 0.5s ease-in-out, margin 0.5s ease-in-out, border-radius 1s 0.25 ease-in-out, color 0.25s ease-in-out 0.25s;
}
.back-to-top:hover:before, .back-to-top.auto:before {
  animation: lineUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
}
.back-to-top:hover:after, .back-to-top.auto:after {
  animation: tipUp 1s cubic-bezier(0, 0.6, 1, 0.4) infinite 0.5s;
}
.back-to-top:before {
  position: absolute;
  display: inline-block;
  content: "";
  background: $white;
  width: 3px;
  height: 16px;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -2px;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
}
.back-to-top:after {
  position: absolute;
  display: inline-block;
  content: "";
  width: 12px;
  height: 12px;
  color: $white;
  border-top: 3px solid;
  border-left: 3px solid;
  transform: rotateZ(45deg);
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: -7px;
  transform: translateY(50px) rotateZ(45deg);
}

@keyframes tipUp {
  0% {
    transform: translateY(50px) rotateZ(45deg);
  }
  100% {
    transform: translateY(-70px) rotateZ(45deg);
  }
}
@keyframes lineUp {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(-70px);
  }
}

/* Image block */
.imageblock-section {
  position: relative;
  display: block;
  width: 100%;
}

.imageblock-section-img {
  position: absolute;
  height: 100%;
  top: 0;
  padding: 0;
  right: 0;
  left: auto;
}

.background-image-holder {
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  background-size: cover !important;
  background-position: 50% 50% !important;
  transition: opacity .3s linear;
  display: block;
  width: 100%;
}

// Social Bg Color
.social-bg-hover {
  &:before {
    content: "";
    color: $white;
    width: 100%;
    height: 100%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: 0;
  }
  position: relative;
  color: $white;
  &:hover {
    color: $white;
    &:before {
      background-color: rgba(black, 0.1);
    }
  }
  span {
    position: relative;
  }
}

.facebook-bg {
  background-color: #445c8e;
}

.twitter-bg {
  background-color: #43afe9;
}

.google-bg {
  background-color: #dc0510;
}

.instagram-bg{
  background-color: #DD2A7B;
}

.linkedin-bg {
  background-color: #007eb3;
}

/* error */
.error-text{
  font-size: 268px;
  line-height: 266px;
  font-weight: 800;
  font-family: $font-hedding;
  color: $gray-9;
}


@media (max-width:991px) {

.imageblock-section-img {
  position: relative;
  height: 400px;
}

}

@media (max-width:767px) {

  /* Section title */
  .section-title {
    margin-bottom: 30px;
  }
  .section-title p{
    margin-bottom: 20px;
   }

  .get-touch{
    margin-top: 50px;
  }

  /* Sticky Image */
  .sticky-img.right-split,
  .sticky-img.left-split{
    position: inherit;
    top: 0;
    width: 90%;
    right: 0;
    left: 0;
    margin: auto;
    margin-bottom:30px;
  }

  /* error */
  .error-text {
    font-size: 210px;
    line-height: 220px;
  }

}


@media only screen and (max-width: 767px) and (min-width: 575px){

    .get-touch svg {
      bottom: -50px;
    }

  }

@media (max-width:575px) {

  /* error */
  .error-text {
    font-size: 120px;
    line-height: 130px;
  }

}


