/*****************************
	Pricing Table
*****************************/

.pricing-table {
	padding: 50px 30px;
	border-radius: $border-radius;

	.pricing-title {
		margin-bottom: 15px;
	}

	.pricing-price {
		padding: 40px 0;
		display: inline-flex;
		align-items: center;

		h2 {
			margin-bottom: 0;
			font-size: 60px;
			font-weight: 600;
		}

		.pricing-duration {
			padding-left: 20px;

			span {
				display: block;
			}

		}

	}

}