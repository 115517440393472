/*****************************
	Team
*****************************/

.team {
	position: relative;
	transition: all 0.5s ease-in-out;

	.team-img {
		position: relative;
		padding-right: 50px;
		border-radius:$border-radius;

		img {
			border-radius:$border-radius;

		}

		.shape {
			position: absolute;
			z-index: -1;
		}

		.shape-01 {
			right: 0px;
			bottom: -75px;
		}

		.shape-02 {
			right: 0px;
			bottom: -55px;
		}

		.shape-03 {
			right: 0px;
			bottom: -76px;
		}

	}

	.team-info {
		position: relative;

		.team-designation {
			font-size: 16px;
			color: $primary;
			margin-top: 25px;
			display: block;
		}

		.team-title {
			font-size: 20px;
			font-weight: 500;
			color: $gray-8;
			margin-top: 10px;
			margin-bottom: 4px;
			display: block;

			&:hover {
				color: $primary;
			}

		}

	}

	&:hover {
		.team-detail {
			.team-social {
				opacity: 1;
			}

		}

	}

}

.team.team-02 {
	text-align: center;

	.team-img {
		position: relative;
		padding-right: 0px;
		overflow: hidden;
		border-radius: $border-radius;

		img {
			border-radius: $border-radius;
		}

		.shape {
			z-index: 9;
			transition: all 0.9s ease-in-out;
		}

		.shape-04 {
			width: 350px;
			height: auto;
			bottom: -170px;
			left: -15px;
		}

		.shape-05 {
			top: -180px;
			left: -85px;
		}

		.team-social {
			position: absolute;
			left: 50%;
			width: 100%;
			transform: translateX(-50%);
			transition: all 0.5s ease-in;
			bottom: -30px;
			z-index: 9;

			ul {
				display: inline-block;
				margin-bottom: 0;
				padding-left: 0;

				li {
					display: inline-block;
					list-style-type: none;
					float: left;

					a {
						display: block;
						color: $white;
						transition: all 0.5s ease-in-out;
						padding: 0 8px;

						&:hover {
							color: $primary;
						}

						i {
							font-size: 16px;
							line-height: 24px;
						}

					}

				}

			}

		}

	}

	&:hover {
		.team-img {
			.shape {
				transition: all 0.5s ease-out;
			}

			.shape-04 {
				bottom: -40px;
				left: -15px;
			}

			.shape-05 {
				top: -70px;
				left: -85px;
			}

			.team-social {
				bottom: 10px;
			}

		}

	}

}

@media (max-width:991px) {

	/* Team */
	.team .team-img {
		padding-right: 0px;
	}

	.team .team-img .shape-01 {
		right: -10px;
		width: 120px
	}

	.team .team-img .shape-02 {
		right: -10px;
		bottom: -90px;
		width: 120px;
	}

	.team .team-img .shape-03 {
		right: -10px;
		width: 120px;
	}

	.team.team-02 .team-img .shape-04 {
		width: 480px;
		bottom: -230px;
	}

}


@media (max-width:575px) {
	.team .team-img .shape-01,
	.team .team-img .shape-02,
	.team .team-img .shape-03 {
		display: none;
	}

	.team.team-02 .team-img .shape-04 {
		width: 580px;
		bottom: -280px;
	}

	.team.team-02:hover .team-img .shape-04 {
		bottom: -80px;
	}

}

@media (max-width:479px) {
	.team.team-02 .team-img .shape-04 {
		width: 480px;
		bottom: -230px;
	}

	.team.team-02:hover .team-img .shape-04 {
		bottom: -40px;
	}

}

@media (max-width:391px) {
	.team.team-02 .team-img .shape-04 {
		width: 400px;
	}

}

