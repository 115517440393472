/*****************************
	Portfolio
*****************************/

.my-shuffle-container {
	margin-left: -15px;
	margin-right: -15px;

	.grid-item {
		width: 33.33%;
		padding: 15px;
	}

}

.portfolio-item {

	img {
			width: 100%;
			transform: scale(1);
			transition: all 0.3s ease;
		}

	.portfolio-img {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 20px;

		i {
			font-size: 20px;
		}

	}

	.portfolio-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s ease;
    .portfolio-img {
    	top:0;
    	left:0;
			width: 100%;
			height: 100%;
			transform: translate(0%,0%);
			right: inherit;
			i {
				position: absolute;
				top:50%;
    		left:50%;
				transform: translate(-50%,-50%);
				font-size: 20px;
				color: $white;
				height: 60px;
		    width: 60px;
		    text-align: center;
		    line-height: 60px;
		    border: 2px solid $primary;
		    border-radius: $border-radius;
		    background: $bg-primary-gradient;;
			}
    }
	}

	.portfolio-info {
		margin-top: 25px;
		margin-bottom: 35px;
		position: relative;

		.portfolio-category {
			margin-bottom: 5px;
			display: block;

			a {
				font-size: 14px;
				font-weight: 600;
				background: -webkit-linear-gradient($primary, $primary-2);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}

		}

		.portfolio-title {
			color: $gray-9;
			font-size: 18px;
			font-weight: 600;
			font-family: $font-hedding;

			&:hover {
				color: $primary;
			}

		}

	}

	&:hover {
		.portfolio-overlay{
			opacity: 1;
			background: rgba($black,0.3);
		}

	}

}

.portfolio-item.portfolio-item-02 {
	overflow: hidden;

	.portfolio-info {
		padding: 20px;
		width: 90%;
		position: absolute;
		left: 50%;
		bottom: 0px;
		z-index: 2;
		opacity: 0;
		border-radius: 5px;
		transition: all 0.5s ease-in-out;
		transform: translate(-50%, 0%);
		background: $white;
		margin-top: 0px;
		margin-bottom: 0px;
	}

	&:hover {
		.portfolio-info {
			opacity: 1;
			bottom: 30px;
		}

	}

}

.my-shuffle-container {
	.portfolio-item {
		.portfolio-info {
			margin-bottom: 0px;
		}

	}

}

.filters-group {
	display: table;
	margin: 0 auto 50px;
	text-align: center;

	button {
		margin: 0 5px 10px;
		padding: 12px 20px;
		cursor: pointer;
		font-size: 18px;
		line-height: 22px;
		font-weight: 600;
		color: $gray-9;
		background: inherit;
		border: none;
		border-radius: $border-radius;
		transition: all 0.3s ease;

		&:hover {
			background: $bg-primary-gradient;
			color: $white;
		}

	}

	button.active {
		background: $bg-primary-gradient;
		color: $white;
	}

}

.bg-dark .portfolio-item {
	.portfolio-info {
		.portfolio-title {
			color: $white;

			&:hover {
				color: $primary;
			}

		}

	}

}

/*************************************
         shuffle
 *************************************/
.my-shuffle-container.grid-style {
	.grid-item {
		padding: 0 !important;
	}

}

.my-shuffle-container.columns-1 {
	.grid-item {
		width: 100%;
		padding: 15px;
	}

}

.my-shuffle-container.columns-2 {
	.grid-item {
		width: 50%;
		padding: 15px;
		float: left;
	}

}

.my-shuffle-container.columns-3 {
	.grid-item {
		width: 33.33333333%;
		padding: 15px;
	}

}

.my-shuffle-container.columns-4 {
	.grid-item {
		width: 25%;
		padding: 10px;
	}

}

.my-shuffle-container.columns-5 {
	.grid-item {
		width: 20%;
		padding: 15px;
	}

}

.my-shuffle-container.columns-6 {
	.grid-item {
		width: 16.666666%;
		padding: 0px;
	}

}

.my-shuffle-container.columns-2 .grid-item-full,
.my-shuffle-container.columns-3 .grid-item-full,
.my-shuffle-container.columns-4 .grid-item-full,
.my-shuffle-container.columns-5 .grid-item-full,
.my-shuffle-container.columns-6 .grid-item-full {
	width: 100%;
}

@media (max-width:767px) {
	.my-shuffle-container .grid-item,
	.my-shuffle-container.columns-3 .grid-item,
	.my-shuffle-container.columns-4 .grid-item,
	.my-shuffle-container.columns-5 .grid-item,
	.my-shuffle-container.columns-6 .grid-item {
		width: 50%;
	}

}

@media (max-width:575px) {
	.my-shuffle-container.columns-2 .grid-item,
	.my-shuffle-container .grid-item {
		width: 100%;
	}

	.my-shuffle-container .grid-item,
	.my-shuffle-container.columns-2 .grid-item,
	.my-shuffle-container.columns-3 .grid-item,
	.my-shuffle-container.columns-4 .grid-item,
	.my-shuffle-container.columns-5 .grid-item,
	.my-shuffle-container.columns-6 .grid-item {
		width: 100%;
	}

}
